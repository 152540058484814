import { SanityHeroFilterList } from "@graphql-types";
import { useStore } from "@state/store";
import { colorsRGB, colors, icons } from "@util/constants";
import { useCheckScreenWidth } from "@util/hooks";
import { Container, P } from "@util/standard";
import React, { useEffect } from "react";
import styled from "styled-components";

interface Props {
  data: SanityHeroFilterList;
  noBorderBottom?: boolean;
  noBorderTop?: boolean;
  isLearnPage?: boolean;
}

const CategoryWrapper = styled(Container)<{ highlighted?: boolean }>`
  background: ${colorsRGB.white(0.2)};
  border-radius: 3px;
  margin: 0 8px 8px 0;
  ${({ highlighted }) => (highlighted ? `opacity: 1;` : `opacity: 0.4;`)};
`;

const Category = styled(P)`
  color: ${colors.white};
  cursor: pointer;
  font-size: 13px;
  padding: 7px 12px;
  margin: auto;
`;

const Icon = styled.object<{ highlighted?: boolean }>`
  margin: auto 12px auto auto;

  ${({ highlighted }) =>
    !highlighted &&
    `
    width: 0px; 
    height: 0px;
    margin: 0;
  `}

  cursor: pointer;
  pointer-events: none;
`;

function FilterList({ data, noBorderBottom, isLearnPage, noBorderTop }: Props) {
  const { searchFilters, setHasSearched } = useStore();
  const { isMobileWidth, isTabletWidth } = useCheckScreenWidth();

  const handleClick = (category: string) => {
    if (searchFilters.includes(category)) {
      const newFilters = searchFilters.filter(filter => filter !== category);
      useStore.setState({ searchFilters: newFilters });
      setHasSearched(true);
    } else {
      const newFilters = searchFilters.concat(category);
      useStore.setState({ searchFilters: newFilters });
      setHasSearched(true);
    }
  };

  if (data == null) {
    return null;
  }

  return (
    <Container
      flexDirection="column"
      borderTop={noBorderTop ? "none" : `1px solid ${colorsRGB.white(0.2)}`}
      borderBottom={noBorderBottom ? "none" : `1px solid ${colorsRGB.white(0.2)}`}
    >
      {(!isTabletWidth || !isMobileWidth) && !isLearnPage && (
        <P color="white" margin="20px 0 8px 0">
          {data.listText?.toUpperCase()}
        </P>
      )}
      <Container margin="0 0 20px 0" flexWrap="wrap">
        <CategoryWrapper
          highlighted={searchFilters.length === 0}
          onClick={() => {
            useStore.setState({ searchFilters: [] });
            setHasSearched(false);
          }}
        >
          <Category>All</Category>
        </CategoryWrapper>
        {data.filterCategories?.map(category => {
          if (category == null || category.name == null) {
            return null;
          }
          return (
            <CategoryWrapper
              highlighted={searchFilters.includes(category.name)}
              onClick={() => handleClick(category.name as string)}
              key={category.id}
            >
              <Category>{category.name}</Category>
              <Container onClick={() => handleClick(category.name as string)} cursor="pointer">
                <Icon
                  highlighted={searchFilters.includes(category.name)}
                  data={icons.categoryClose}
                  type="image/svg+xml"
                />
              </Container>
            </CategoryWrapper>
          );
        })}
      </Container>
    </Container>
  );
}

export default FilterList;
