import React, { useEffect } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";
import { Container, P } from "@util/standard";
import { colors, colorsRGB, fontWeights, icons } from "@util/constants";
import { useStore } from "@state/store";
import { usePrevious } from "@util/hooks";

const CategoryWrapper = styled(Container)<{ highlighted?: boolean }>`
  background: ${colorsRGB.white(0.2)};
  border-radius: 3px;
  margin: 0 8px 8px 0;
  max-height: 32px;
  ${({ highlighted }) => (highlighted ? `opacity: 1;` : `opacity: 0.4;`)};
`;

const Category = styled(P)<{ highlighted?: boolean }>`
  color: ${colors.white};
  cursor: pointer;
  font-size: 13px;
  padding: 7px 12px;
  margin: auto;
  font-weight: ${fontWeights.bold};
`;

const Icon = styled.object<{ highlighted?: boolean }>`
  margin: auto 12px auto auto;

  ${({ highlighted }) =>
    !highlighted &&
    `
  width: 0px; 
  height: 0px;
  margin: 0;
  `}
  cursor: pointer;
  pointer-events: none;
`;

function CategoryPicker() {
  const { allSanityRecipeCategories }: Query = useStaticQuery(graphql`
    {
      allSanityRecipeCategories {
        nodes {
          id
          name
        }
      }
    }
  `);

  const { searchFilters, setHasSearched } = useStore();
  const prevSearchFilters = usePrevious(searchFilters);

  useEffect(() => {
    if (searchFilters.length !== prevSearchFilters.length) {
      if (searchFilters.length > 0) {
        setHasSearched(true);
        return;
      }

      setHasSearched(false);
    }
  }, [searchFilters]);

  const handleClick = (category: string) => {
    if (searchFilters.includes(category)) {
      const newFilters = searchFilters.filter(filter => filter !== category);
      useStore.setState({ searchFilters: newFilters });
    } else {
      const newFilters = searchFilters.concat(category);
      useStore.setState({ searchFilters: newFilters });
    }
  };

  return (
    <Container width="92%" flexWrap="wrap" margin="15% 0 0 0">
      <CategoryWrapper highlighted={searchFilters.length === 0}>
        <Category highlighted={searchFilters.length === 0} onClick={() => useStore.setState({ searchFilters: [] })}>
          All Recipes
        </Category>
      </CategoryWrapper>
      {allSanityRecipeCategories.nodes.map((item, index) => {
        if (item == null || item.name == null || index > 14) {
          return null;
        }

        return (
          <CategoryWrapper
            highlighted={searchFilters.includes(item.name)}
            onClick={() => handleClick(item.name as string)}
            key={item.id}
          >
            <Category highlighted={searchFilters.includes(item.name)}>{item.name}</Category>

            <Container cursor="pointer">
              <Icon highlighted={searchFilters.includes(item.name)} data={icons.categoryClose} type="image/svg+xml" />
            </Container>
          </CategoryWrapper>
        );
      })}
    </Container>
  );
}

export default CategoryPicker;
