import { Container } from "@util/standard";
import { SearchBox } from "react-instantsearch-dom";
import React, { useState } from "react";
import styled from "styled-components";
import { colorsRGB, fontFamilies, icons, TABLET_BREAKPOINT } from "@util/constants";
import { isBrowser } from "@util/helper";
import { useStore } from "@state/store";

const SearchBarContainer = styled(Container)`
  background: ${colorsRGB.white(0.1)};
  justify-content: space-between;

  width: 92%;
  margin: 5% 0;
  .ais-SearchBox {
    width: 100%;
    button.ais-SearchBox-submit {
      display: none;
    }
  }

  .ais-SearchBox-form {
    display: flex;
    flex-direction: row;
  }

  .ais-SearchBox-reset {
    background: transparent;
    border: none;
  }

  .ais-SearchBox-input {
    width: 100%;
    color: ${colorsRGB.white(1)};
    font-family: ${fontFamilies.proximaNova};
    background: transparent;
    outline: none;
    border: none;
    padding: 12px 20px;
    content: "Hello";
    ::placeholder {
      color: ${colorsRGB.white(0.5)};
    }

    ::-webkit-search-cancel-button {
      display: none;
    }
  }

  :focus-within {
    img {
      opacity: 1;
    }
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    input {
      font-size: 16px;
    }
    select:focus,
    textarea:focus,
    input:focus {
      font-size: 16px;
    }
  }
`;

const Icon = styled.img<{ width?: string; height?: string }>`
  margin-right: 13px;
  filter: brightness(10);
  cursor: pointer;
  ${({ width }) => width && `width: ${width};`};
  ${({ height }) => height && `height: ${height};`};
`;

function SearchBar() {
  const { currentSearch, searchParams } = useStore();

  const scrollToResults = e => {
    if (isBrowser()) {
      const results = document.querySelector("#searchResults");
      if (results == null) {
        return null;
      }

      results.scrollIntoView({ behavior: "smooth" });
      return;
    }
    return;
  };

  const update = (value: string) => {
    // updateSearchEntry(value);

    useStore.setState({
      searchParams: {
        searchString: value,
        hasSearched: value.length > 0 ? true : false,
        globalSearch: false,
      },
      currentSearch: value,
    });
  };

  const handleReset = () => {
    useStore.setState({
      searchParams: {
        searchString: "",
        hasSearched: false,
        globalSearch: false,
      },
      currentSearch: "",
    });
  };

  return (
    <SearchBarContainer>
      <SearchBox
        defaultRefinement={currentSearch ? currentSearch : undefined}
        onChange={e => update(e.target.value)}
        onSubmit={e => {
          e.preventDefault();
          scrollToResults(e);
        }}
        onReset={handleReset}
        reset={<Icon width="12px" height="12px" src={icons.close} onClick={() => handleReset()} />}
      />
      <Icon src={icons.search} onClick={e => scrollToResults(e)} />
    </SearchBarContainer>
  );
}

export default SearchBar;
