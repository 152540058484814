import React, { useEffect } from "react";
import styled from "styled-components";
import { SanityHero, SanityHeroFilterList } from "@graphql-types";
import { Container, Gradient, H1, P } from "@util/standard";
import { RefinementList } from "react-instantsearch-dom";

import { BlocksContent, Image, Video } from ".";
import Cta from "./cta";
import {
  animationTypes,
  colors,
  colorsRGB,
  fontWeights,
  gradients,
  IPAD_PRO_BREAKPOINT,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from "@util/constants";
import AnimationContainer from "./animationContainer";
import { useStore } from "@state/store";
import SearchBar from "./searchBar";
import CategoryPicker from "@components/hero/categoryPicker";
import { useCheckScreenWidth, useHasMounted } from "@util/hooks";
import FilterList from "@components/hero/filterList";
import Button from "./button";
import Accordion from "./accordion";

interface Props {
  data: SanityHero;
  filterLists?: SanityHeroFilterList[];
  noMaxHeight?: boolean;
  isHomePage?: boolean;
  searchBar?: boolean;
  isLearnPage?: boolean;
  isRecipePage?: boolean;
}

const RightColumnContainer = styled(Container)`
  h1 {
    font-size: 60px;
    margin: 0;
    font-weight: ${fontWeights.normal};
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    h1 {
      font-size: 42px;
      line-height: 46px;
    }
  }
`;

const HiddenRefinement = styled(RefinementList)`
  display: none;
`;

const Logo = styled.img`
  display: block;
  margin: auto;
`;

const StyledImage = styled(Image)<{ isHomePage?: boolean }>`
  .gatsby-image-wrapper {
    width: 50% !important;
  }
`;

const AllRecipeButtonWrapper = styled(Container)<{ highlighted?: boolean }>`
  @media only screen and (min-width: ${TABLET_BREAKPOINT}px) {
    display: none;
  }
  background: ${colorsRGB.white(0.2)};
  border-radius: 3px;
  margin: 0 8px 8px 7%;
  max-height: 32px;
`;

const AllRecipeButton = styled(P)<{ highlighted?: boolean }>`
  color: ${colors.white};
  cursor: pointer;
  font-size: 13px;
  padding: 7px 12px;
  margin: auto;
  font-weight: ${fontWeights.bold};
`;

const BlurContainer = styled(Container)`
  position: absolute;
  top: 0px;
  right: 0px;
  backdrop-filter: blur(10px);
  height: 100%;
  width: 50%;
  z-index: 0;
`;

function Hero({ data, isHomePage, searchBar, isLearnPage, filterLists, isRecipePage, noMaxHeight }: Props) {
  const { isLaptopWidth, isMobileWidth, isTabletWidth } = useCheckScreenWidth();
  const { user, isLoggedIn, searchFilters, setHasSearched } = useStore();

  const enableAnimation = isHomePage && isLaptopWidth;

  return (
    <Container
      height={noMaxHeight && !isLaptopWidth ? "max-content" : isHomePage ? "83vh" : "88vh"}
      minHeight={isLaptopWidth ? "730px" : "100%"}
      width="100%"
    >
      <Container width="100%" height="100%" position="relative">
        <Container width="100%" flexDirection={!isLaptopWidth ? "column" : "row"} position="relative">
          {data.heroImage && !data.heroVideo && (
            <Container
              width={isHomePage ? "100%" : "50%"}
              tabletWidth="100%"
              height={!isLaptopWidth ? "55%" : "100%"}
              position="absolute"
            >
              <StyledImage isHomePage={isHomePage} isBackground image={data.heroImage?.asset?.gatsbyImageData} />
              <Gradient gradient={gradients.heroGradient} />
            </Container>
          )}
          {data.heroVideo && data.useVideo && (
            <Container width="100%" height="100%" position="absolute" backgroundColor="darkerGreen">
              <Video
                background
                hidePlayButton
                width={"200vw"}
                ipadProWidth="170vh"
                tabletWidth="300vh"
                height="150vh"
                controls={false}
                url={data.heroVideo.url as string}
                id={data.heroVideo.url as string}
              />
              <Gradient gradient={gradients.heroGradient} />
              <AnimationContainer
                delay={4000}
                config={{ duration: 500 }}
                disableVisibilitySensor
                animationType={animationTypes.fadeIn}
              >
                {isHomePage && isLaptopWidth && !data.hideBackgroundColor && <BlurContainer />}
              </AnimationContainer>
            </Container>
          )}
          <Container width="50%" height="72%" zIndex={2} justifyContent="center" alignItems="flex-end">
            {data.heroLogo && data.heroLogoDisplay && isLaptopWidth && (
              <AnimationContainer
                disableVisibilitySensor
                delay={4000}
                animationType={enableAnimation ? animationTypes.slideInFromRight("50%") : animationTypes.noAnimation}
                config={{ duration: 500 }}
              >
                <AnimationContainer
                  disableVisibilitySensor
                  animationType={enableAnimation ? animationTypes.fadeIn : animationTypes.noAnimation}
                  config={{ duration: 500 }}
                  delay={2000}
                >
                  <AnimationContainer
                    disableVisibilitySensor
                    animationType={
                      enableAnimation ? animationTypes.slideInFromTop("-100px", 1) : animationTypes.noAnimation
                    }
                    delay={6000}
                  >
                    <Logo src={data.heroLogo?.asset?.url as string} />
                  </AnimationContainer>
                </AnimationContainer>
              </AnimationContainer>
            )}
          </Container>

          <Container
            width="50%"
            tabletWidth="100%"
            height={
              !isLaptopWidth && !isLearnPage && !noMaxHeight
                ? "60%"
                : isLearnPage && isMobileWidth && !noMaxHeight
                ? "60%"
                : "100%"
            }
            margin={noMaxHeight && !isLaptopWidth ? "60% 0 0 0" : ""}
            position="relative"
          >
            <AnimationContainer
              disableVisibilitySensor
              animationType={enableAnimation ? animationTypes.slideInFromRight("50%", 0) : animationTypes.noAnimation}
              delay={4000}
              config={{ duration: 500 }}
            >
              {useHasMounted() && (
                <Container
                  width="100%"
                  height={noMaxHeight && !isLaptopWidth ? null : "100%"}
                  maxHeight={noMaxHeight ? "" : isLearnPage && isMobileWidth ? "500px" : "100%"}
                  backgroundRGBColor={
                    data.hideBackgroundColor
                      ? "transparent"
                      : isLaptopWidth && isHomePage
                      ? colorsRGB.darkerGreen(0.92)
                      : !isHomePage && !data.hideBackgroundColor
                      ? colors.mossGreen
                      : "transparent"
                  }
                  zIndex={2}
                  alignItems="center"
                >
                  <RightColumnContainer
                    margin="auto"
                    width="71%"
                    zIndex={3}
                    mobileWidth={isLearnPage ? "75%" : "92%"}
                    height={isLearnPage ? "80%" : "70%"}
                    flexDirection="column"
                    maxWidth="505px"
                    padding={isLearnPage ? "50px 0" : "0"}
                    justifyContent={isLaptopWidth && (isHomePage || isLearnPage) ? "flex-end" : "center"}
                  >
                    <AnimationContainer
                      disableVisibilitySensor
                      animationType={
                        enableAnimation ? animationTypes.slideInFromTop("-100px", 0) : animationTypes.noAnimation
                      }
                      delay={6000}
                    >
                      {isHomePage && !isLoggedIn && (
                        <BlocksContent whiteText blocks={data.heroColumn?._rawColumnContent} />
                      )}
                      {isHomePage && isLoggedIn && user && (
                        <H1 fontWeight="bold" width="85%" color="white">
                          Welcome back {user.firstName}
                        </H1>
                      )}
                      {isRecipePage && searchFilters.length === 1 && (
                        <H1 fontWeight="bold" color="white">
                          {searchFilters[0]}
                        </H1>
                      )}
                      {isRecipePage && searchFilters.length > 1 && (
                        <H1 fontWeight="bold" color="white">
                          Filter Results
                        </H1>
                      )}
                      {!isHomePage && (searchFilters.length === 0 || !isRecipePage) && (
                        <BlocksContent whiteText blocks={data.heroColumn?._rawColumnContent} />
                      )}

                      {searchBar && <SearchBar />}
                      <Container margin={isHomePage ? "40px 0 0 0" : "0"} mobileMargin="20px 0 0 0">
                        {data.heroCtas?.map((cta, index) => {
                          if (cta == null) {
                            return null;
                          }
                          return (
                            <Cta
                              padding={
                                !isLaptopWidth
                                  ? "0px 15px"
                                  : index !== 0 && cta.ctaStyle === "text"
                                  ? "0 0 0 25px"
                                  : index === 0 && cta.ctaStyle === "text"
                                  ? "0"
                                  : null
                              }
                              data={cta}
                              key={cta._key}
                            />
                          );
                        })}
                        {!isHomePage && !isLearnPage && searchFilters && searchFilters.length > 0 && (
                          <AllRecipeButtonWrapper
                            onClick={() => {
                              useStore.setState({ searchFilters: [] });
                              setHasSearched(false);
                            }}
                          >
                            <AllRecipeButton>All Recipes</AllRecipeButton>
                          </AllRecipeButtonWrapper>
                        )}
                      </Container>
                      {searchBar && isLaptopWidth && <CategoryPicker />}
                      {searchBar && (
                        <HiddenRefinement
                          attribute="recipeCategories"
                          defaultRefinement={searchFilters}
                          operator="and"
                        />
                      )}
                      {isLearnPage && filterLists && isLaptopWidth && (
                        <Container flexDirection="column" margin="3% 0 0 0">
                          {filterLists.map((list, index) => {
                            if (list == null || list.filterCategories == null) {
                              return null;
                            }
                            const isFirst = index === 0 && filterLists.length > 1;
                            return <FilterList data={list} noBorderBottom={isFirst} key={list._key} />;
                          })}
                          <HiddenRefinement attribute="blogSubCategories" defaultRefinement={searchFilters} />
                        </Container>
                      )}
                      {isLearnPage && filterLists && (isTabletWidth || isMobileWidth) && (
                        <Container flexDirection="column" margin="3% 0 0 0">
                          {filterLists.map((list, index) => {
                            if (list == null || list.filterCategories == null) {
                              return null;
                            }
                            const isFirst = index === 0 && filterLists.length > 1;
                            return (
                              <Accordion text={list.listText?.toUpperCase() as string} whiteVersion>
                                <FilterList
                                  data={list}
                                  noBorderBottom={isFirst}
                                  key={list._key}
                                  isLearnPage
                                  noBorderTop
                                />
                              </Accordion>
                            );
                          })}
                          <HiddenRefinement attribute="blogSubCategories" defaultRefinement={searchFilters} />
                        </Container>
                      )}
                    </AnimationContainer>
                  </RightColumnContainer>
                </Container>
              )}
            </AnimationContainer>
          </Container>
        </Container>
      </Container>
    </Container>
  );
}

export default Hero;
